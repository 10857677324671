type EconomicEfficiencyItem = {
    valuePreLabel?: string;
    value: number;
    units: string;
    valueLabel: string;
};

export type ProjectInfo = {
    slug: string;
    projectSlug: string;
    material: string;
    status: string;
    title: string;
    valueOfMaterialsPreLabel?: string;
    valueOfMaterialsLabel: string;
    valueOfMaterials: number;
    valueOfMaterialsUnits: string;
    description?: string;
    subtitle?: string;
    stageList?: string[];
    footerTitle?: string;
    caption?: string;
    economic?: EconomicEfficiencyItem[];
    image: string;
    template: string;
    video?: {
        popupName: string;
        duration: string;
        name: string;
    };
};

export type PossibilitiesProjectInfo = {
    slug: string;
    projectSlug: string;
    material: string;
    status: string;
    title: string;
    description?: string;
    image: string;
    template: string;
    info: {
        title: string;
        subtitle: string;
    }[];
    pluses: string[];
    minuses: string[];
};

export const projectsData: ProjectInfo[] = [
    {
        slug: 'barnaulskaya-tets-3',
        projectSlug: 'uchastok-avtomobilnoj-dorogi-pavlovsk-–-topchiha-v-altajskom-krae',
        material: 'Зола-уноса',
        status: 'Реализованный',
        title: 'Участок автомобильной дороги "Павловск – Топчиха" в Алтайском крае',
        footerTitle:
            'Пилотный проект строительства участка автомобильной дороги с использованием золы-уноса для укрепления дорожного полотна.',
        valueOfMaterials: 25,
        valueOfMaterialsLabel: 'Объем использования золы-уноса',
        valueOfMaterialsUnits: 'тонн',
        image: 'item-12',
        template: 'template-three',
        video: {
            popupName: 'video-player-project-12',
            name: 'Ролик о проекте',
            duration: '01:19',
        },
    },
    {
        slug: 'novosibirskaya-tets-3',
        projectSlug: 'skladskoj-kompleks-ploshhadju-30-tys-kvm-v-kirovskom-rajone-g-novosibirska',
        material: 'зола-уноса',
        status: 'текущий',
        title: 'Складской комплекс в г. Новосибирске',
        description:
            'Для подготовки участка складского комплекса используется зола-уноса новосибирских ТЭЦ-3 и ТЭЦ-5. Материал применяется для стабилизации грунта земляного полотна.',
        valueOfMaterialsLabel: 'Объем использования золы-уноса',
        valueOfMaterialsPreLabel: 'до',
        valueOfMaterials: 4800,
        valueOfMaterialsUnits: 'тонн',
        footerTitle:
            'При добавлении в грунт зола устраняет избыточную влагу, придавая грунту устойчивость. Используется в качестве альтернативы извести, как более экономичный вариант с аналогичной эффективностью.',
        image: 'item-13',
        template: 'template-three',
        video: {
            popupName: 'video-player-project-13',
            name: 'Ролик о проекте',
            duration: '00:46',
        },
    },
    {
        slug: 'novosibirskaya-tets-5',
        projectSlug:
            'avtomobilnaja-doroga-r256-«chujskij-trakt»-na-uchastke-novosibirsk-–-linevo-«vostochnyj-obhod»-3-jetap',
        material: 'Золошлаки',
        status: 'Перспективный',
        title: 'Автомобильная дорога Р-256 «Чуйский тракт» на участке Новосибирск – Линево («Восточный обход») (3 этап)',
        description:
            'Строительство автомобильной дороги с использованием золошлаков в качестве материала земляного полотна. <br/> Заключен договор на проведение комплекса обследований золошлаков для определения их потенциальной пригодности (между СГК и РосИнсталПроект — проектировщиком объекта)',
        valueOfMaterialsLabel: 'Потенциальный Объем использования золошлаков',
        valueOfMaterialsPreLabel: 'до',
        valueOfMaterials: 175,
        valueOfMaterialsUnits: 'тыс. тонн',
        image: 'item-11',
        template: 'template-four',
        economic: [
            {
                valuePreLabel: 'до ',
                value: 17,
                units: '%',
                valueLabel: 'от сметной стоимости строительства земляного полотна на рассматриваемых участках',
            },
            {
                value: 17.5,
                units: ' млн руб.',
                valueLabel: 'экономия в рамках проекта.',
            },
        ],
        caption: 'Согласно Техническому отчету ООО «РосИнсталПроект» № 312/2023-2023-ТО/1 от 10.10.2023 г.',
    },
    {
        slug: 'tom-usinskaya-gres',
        projectSlug: 'avtomobilnaja-doroga-sharap-–-vostochnyj-v-kemerovskoj-oblasti',
        material: 'Шлаковый песок',
        status: 'Перспективный',
        title: 'Автомобильная дорога Шарап – Восточный в Кемеровской области',
        description:
            'Строительство автомобильной дороги с использованием шлакового песка в качестве материала рабочего слоя земляного полотна, а также для отсыпки дополнительных слоев оснований дорожных одежд. Проведено технико-экономическое обоснование включения золошлаков в проект (специалистами СибАДИ по инициативе СГК)',
        valueOfMaterialsLabel: 'Потенциальный Объем использования золошлаков',
        valueOfMaterialsPreLabel: 'до',
        valueOfMaterials: 273,
        valueOfMaterialsUnits: 'тыс. куб.м.',
        subtitle: 'Экономическая эффективность',
        caption:
            'Согласно Техническому отчету Федерального государственного бюджетного образовательного учреждения высшего образования «Сибирский государственный автомобильно-дорожный университет (СибАДИ)» №ТУГРЭС-23-1417-2023-ТО/1 (апрель 2023)',
        economic: [
            {
                valuePreLabel: 'до ',
                value: 10,
                units: '%',
                valueLabel: 'от сметной стоимости строительства дорожной одежды',
            },
            {
                value: 111,
                units: ' млн руб.',
                valueLabel: 'экономия в рамках проекта',
            },
        ],
        image: 'item-10',
        template: 'template-four',
    },
    {
        slug: 'novosibirskaya-tets-5',
        projectSlug: 'proizvodstvo-krovelnyh-gidroizoljacionnyh-materialov-tehnonikol',
        material: 'Зола-уноса',
        status: 'Действующий',
        title: 'Производство кровельных гидроизоляционных материалов "Технониколь"',
        valueOfMaterialsLabel: 'Объем использования золы-уноса',
        valueOfMaterials: 40,
        valueOfMaterialsUnits: 'тыс. тонн в год',
        description:
            'Завод "Технофлекс" по производству рулонных материалов в г. Юрга Кемеровской области входит в группу компаний "Технониколь" и является одним из крупнейших производителей мягкой кровли в России.',
        footerTitle:
            'Технология производства кровельных гидроизоляционных материалов на заводе изначально включает применение золы-уноса угольных ТЭЦ.',
        caption:
            'Предприятие использует золу в качестве минеральной добавки к битумно-полимерной смеси для обеспечения требуемой вязкости материала.',
        image: 'item-7',
        template: 'template-three',
    },
    {
        slug: 'reftinskaya-gres',
        projectSlug: 'proizvodstvo-avtoklavnogo-gazozolobetona-tvinblok',
        material: 'Зола-уноса',
        status: 'Действующий',
        title: 'Производство автоклавного газозолобетона "Твинблок"',
        valueOfMaterialsLabel: 'Объем использования золы-уноса',
        valueOfMaterialsPreLabel: '>',
        valueOfMaterials: 200,
        valueOfMaterialsUnits: 'тыс. тонн в год',
        description:
            'ООО "ПСО "Теплит" – крупнейший производитель стеновых газобетонных блоков на рынке Уральского федерального округа. Компания была основана в 1989 г. на базе Рефтинской ГРЭС специально для переработки золы-уноса электростанции. В 2004 г. компания открыла второй завод в г. Березовский.',
        footerTitle:
            'Завод использует золу-уноса Рефтинской ГРЭС в качестве кремнеземистого компонента – одного из основных в технологическом процессе производства продукции.',
        image: 'item-6',
        template: 'template-three',
    },
    {
        slug: 'nazarovskaya-gres',
        projectSlug: 'rekultivacija-otrabotannogo-uchastka-ugolnogo-razreza-nazarovskij',
        material: 'Золошлаки',
        status: 'Реализованный',
        title: 'Рекультивация отработанного участка угольного разреза "Назаровский"',
        footerTitle:
            'Площадь земельного участка – 15 га. На техническом этапе рекультивации использованы золошлаковые материалы из золоотвала №1 Назаровской ГРЭС.',
        valueOfMaterialsLabel: 'Объем использования золошлаковых материалов',
        valueOfMaterials: 1.33,
        valueOfMaterialsUnits: 'млн тонн',
        image: 'item-1',
        template: 'template-two',
    },
    {
        slug: 'kuznetskaya-tets',
        projectSlug: 'rekultivacija-gornyh-otvalov-likvidirovannoj-shahty-bajdaevskaja',
        material: 'Золошлаки',
        status: 'Действующий',
        title: 'Рекультивация горных отвалов ликвидированной шахты "Байдаевская"',
        valueOfMaterialsLabel: 'Объем использования золошлаковых материалов',
        valueOfMaterials: 1.92,
        valueOfMaterialsUnits: 'млн тонн',
        description:
            'Проект предусматривает рекультивацию нарушенных земель, расположенных в Орджоникидзевском районе г. Новокузнецка. Территория участка местами изрыта, поверхностный слой отсутствует, имеются участки проседания поверхности. Основной задачей осуществления проекта было предотвратить на участке рекультивации оползни.',
        footerTitle:
            'Проект реализуется в рамках соглашения о социально-экономическом сотрудничестве с администрацией г. Новокузнецка.',
        image: 'item-2',
        template: 'template-three',
    },
    {
        slug: 'novo-kemerovskaya-tets',
        projectSlug: 'rekultivacija-nejekspluatiruemogo-zolootvala-№1-novokemerovskoj-tjec',
        material: 'Золошлаки',
        status: 'Действующий',
        title: 'Рекультивация неэксплуатируемого золоотвала №1 Ново-Кемеровской ТЭЦ',
        valueOfMaterialsLabel: 'Объем использования золошлаковых материалов',
        valueOfMaterials: 3.6,
        valueOfMaterialsUnits: 'млн тонн',
        description:
            'Площадь земельного участка – 65 га. В ходе реализации работ по техническому этапу проекта будет проводиться планировка нарушенного земельного участка с использованием золошлаковых материалов, производимых на золоотвале №2 Ново-Кемеровской ТЭЦ. В рамках проводимых работ также будет произведено выполаживание склона.',
        footerTitle:
            'Данный объект был выведен из эксплуатации, законсервирован и больше не использовался для складирования золошлаков с 1984г.',
        image: 'item-3',
        template: 'template-three',
    },
    {
        slug: 'minusinskaya-tets',
        projectSlug: 'rekultivacija-narushennyh-zemel-v-g-minusinske',
        material: 'Золошлаки',
        status: 'Реализованный',
        title: 'Рекультивация нарушенных земель в г. Минусинске',
        valueOfMaterialsLabel: 'Объем использования золошлаковых материалов',
        valueOfMaterials: 0.3,
        valueOfMaterialsUnits: 'млн тонн',
        description:
            'Площадь земельного участка – 6,5 га. Территория участка была нарушена и представляла собой заброшенную остаточную карьерную выработку. Поверхность дна выработки была частично изрыта и занята бытовым и строительным мусором.',
        footerTitle:
            'Для реализации проекта был заключен договор аренды земельного участка между Администрацией г. Минусинска и Минусинской ТЭЦ.',
        image: 'item-4',
        template: 'template-three',
    },
    {
        slug: 'kanskaya-tets',
        projectSlug: 'tushenie-vozgoranij-na-ligninohranilishhe-kanskogo-biohimicheskogo-zavoda',
        material: 'Золошлаки',
        status: 'Реализованный',
        title: 'Тушение возгораний на лигнинохранилище Канского биохимического завода',
        valueOfMaterialsLabel: 'Объем использования золошлаковой смеси',
        valueOfMaterials: 101,
        valueOfMaterialsUnits: 'тыс. тонн',
        description:
            'На полигоне Канского биохимического завода произошло самовозгорание лигнина (производственного отхода предприятия) с выделением дыма, пыли и угарного газа. В качестве одной из первоочередных мер для ликвидации горения было решено создать защитный саркофаг из золошлаков Канской ТЭЦ, расположенной в 15 км от полигона. За счет использования золошлаков в качестве покрывного материала был перекрыт доступ воздуха к лигнину, что позволило остановить возгорание. Позже были обнаружены прогары лигнинохранилища в местах, где саркофаг из золошлаков создан не был. С целью ликвидации прогаров Канская ТЭЦ поставила дополнительный объем сырья.',
        footerTitle:
            'За счет использования золошлаков в качестве покрывного материала был перекрыт доступ воздуха к лигнину, что позволило остановить возгорание.',
        image: 'item-5',
        template: 'template-three',
    },
    {
        slug: 'novosibirskaya-tets-5',
        projectSlug: 'skladskoj-kompleks-ploshhadju-30-tys-kvm-v-kirovskom-rajone-g-novosibirska',
        material: 'зола-уноса',
        status: 'текущий',
        title: 'Складской комплекс в г. Новосибирске',
        description:
            'Для подготовки участка складского комплекса используется зола-уноса новосибирских ТЭЦ-3 и ТЭЦ-5. Материал применяется для стабилизации грунта земляного полотна.',
        valueOfMaterialsLabel: 'Объем использования золы-уноса',
        valueOfMaterialsPreLabel: 'до',
        valueOfMaterials: 4800,
        valueOfMaterialsUnits: 'тонн',
        footerTitle:
            'При добавлении в грунт зола устраняет избыточную влагу, придавая грунту устойчивость. Используется в качестве альтернативы извести, как более экономичный вариант с аналогичной эффективностью.',
        image: 'item-13',
        template: 'template-three',
        video: {
            popupName: 'video-player-project-13',
            name: 'Ролик о проекте',
            duration: '00:46',
        },
    },
];

export const possibilitiesProjectsData: PossibilitiesProjectInfo[] = [
    {
        slug: 'possibilities-project-1',
        projectSlug: 'possibilities-project-1',
        material: 'Золошлаки',
        status: 'Перспективный',
        title: 'Конструкции дорожных одежд для автомагистралей с бетонным покрытием',
        image: 'item-1',
        template: 'template-five',
        info: [
            {
                title: 'Цементобетон Btb 4.8 F200 по ГОСТ 26633 (КМД до 40%)',
                subtitle: 'Толщина слоя 24 см</br>Модуль упругости 30 000 МПа',
            },
            {
                title: 'Выравнивающий слой из черного песка ВСН123-77',
                subtitle: 'Толщина слоя 3 см',
            },
            {
                title: 'Песок укрепленный 5% цемента и 8% золы-унос соответствующий М60 по ГОСТ 23358 или ГОСТ Р 70452',
                subtitle: 'Толщина слоя 18 см</br>Модуль упругости 550 МПа',
            },
            {
                title: 'Супесь укрепленная 3% цемента и 6% золы-унос соответствующая М20 по ГОСТ 23358 или ГОСТ Р 70452',
                subtitle: 'Толщина слоя 20 см</br>Модуль упругости 250 МПа',
            },
            {
                title: 'Песок средней крупности по ГОСТ 8736',
                subtitle: 'Толщина слоя 20 см</br>Модуль упругости 120 МПа',
            },
            {
                title: 'Стабилизированный грунт/ЗШС по ГОСТ Р 70452',
                subtitle: 'Толщина слоя 40 см</br>Модуль упругости не менее 60 МПа',
            },
        ],
        pluses: [
            'Не требуется использование синтетических прослоек препятствующих взаимопроникновению слоев',
            'Снижение стоимости конструкции и потребности в материалах',
            'Повышенные параметры качества конструкции, отсутствие сдвигов характерных для дискретных материалов',
        ],
        minuses: ['Технология работ требует технологического перерыва и специализированной техники'],
    },
    {
        slug: 'possibilities-project-2',
        projectSlug: 'possibilities-project-2',
        material: 'Золошлаки',
        status: 'Перспективный',
        title: 'Конструкции дорожных одежд для автомагистралей с асфальтобетонным покрытием',
        image: 'item-2',
        template: 'template-five',
        info: [
            {
                title: 'ЩМА 16 по ГОСТ Р 58406.1 на ПБВ 90',
                subtitle: 'Толщина слоя 5 см</br>Модуль упругости 2 500 МПа',
            },
            {
                title: 'А22Нн по ГОСТ Р 58406.2 на БНД 100/130 (Мин. пор. 3-4%)',
                subtitle: 'Толщина слоя 8 см</br>Модуль упругости 3 000 МПа',
            },
            {
                title: 'А32Он по ГОСТ Р 58406.2 на БНД 100/130 (Мин. пор. 3-5%)',
                subtitle: 'Толщина слоя 10 см</br>Модуль упругости 2 300 МПа',
            },
            {
                title: 'ЩПС укрепленная 4% цемента и 7% золы-унос соответствующая М40 по ГОСТ 23358 или ГОСТ Р 70455',
                subtitle: 'Толщина слоя 18 см</br>Модуль упругости 600 МПа',
            },
            {
                title: 'Супесь укрепленная 3% цемента и 6% золы-унос соответствующая М10 по ГОСТ 23358 или ГОСТ Р 70452',
                subtitle: 'Толщина слоя 30 см</br>Модуль упругости 160 МПа',
            },
            {
                title: 'Стабилизированный грунт/ЗШС по ГОСТ Р 70452',
                subtitle: 'Толщина слоя 50 см</br>Модуль упругости не менее 60 МПа',
            },
        ],
        pluses: [
            'Не требуется использование синтетических прослоек препятствующих взаимопроникновению слоев',
            'Снижение стоимости конструкции и потребности в материалах',
            'Повышенные параметры качества конструкции, отсутствие сдвигов, меньше осадки',
            'Жесткое основание дорожных одежд предотвращает просадки земляного полотна и летнее колееобразование',
        ],
        minuses: ['Технология работ требует технологического перерыва и специализированной техники'],
    },
    {
        slug: 'possibilities-project-3',
        projectSlug: 'possibilities-project-3',
        material: 'Золошлаки',
        status: 'Перспективный',
        title: 'Конструкции дорожных одежд для автомобильных дорог с твердым покрытием',
        image: 'item-3',
        template: 'template-five',
        info: [
            {
                title: 'ЩПС укрепленная 4% цемента и 7% золы-унос соответствующая М40 по ГОСТ 23358 или ГОСТ Р 70455 с добавлением ЭБДК-М.',
                subtitle: 'Толщина слоя 14 см</br>Модуль упругости 600 МПа',
            },
            {
                title: 'Супесь укрепленная 3% цемента и 6% золы-унос соответствующая М10 по ГОСТ 23358 или ГОСТ Р 70452',
                subtitle: 'Толщина слоя 18 см</br>Модуль упругости 160 МПа',
            },
            {
                title: 'Стабилизированный грунт/ЗШС по ГОСТ Р 70452',
                subtitle: 'Толщина слоя 20 см</br>Модуль упругости не менее 60 МПа',
            },
        ],
        pluses: [
            'Не требуется использование привозного каменного материала',
            'Можно работать с грунтами любой влажности из выемок',
            'Повышенные параметры качества конструкции, отсутствие сдвигов характерных для дискретных материалов',
            'Предотвращает появление просадок',
        ],
        minuses: ['Технология работ требует технологического перерыва и специализированной техники'],
    },
    {
        slug: 'possibilities-project-4',
        projectSlug: 'possibilities-project-4',
        material: 'Золошлаки',
        status: 'Перспективный',
        title: 'Конструкции дорожных одежд для автомобильных дорог с твердым покрытием',
        image: 'item-3',
        template: 'template-five',
        info: [
            {
                title: 'ЩПС укрепленная 4% цемента и 7% золы-унос соответствующая М40 по ГОСТ 23358 или ГОСТ Р 70455 с добавлением ЭБДК-М.',
                subtitle: 'Толщина слоя 14 см</br>Модуль упругости 600 МПа',
            },
            {
                title: 'Супесь укрепленная 3% цемента и 6% золы-унос соответствующая М10 по ГОСТ 23358 или ГОСТ Р 70452',
                subtitle: 'Толщина слоя 18 см</br>Модуль упругости 160 МПа',
            },
            {
                title: 'Стабилизированный грунт/ЗШС по ГОСТ Р 70452',
                subtitle: 'Толщина слоя 20 см</br>Модуль упругости не менее 60 МПа',
            },
        ],
        pluses: [
            'Не требуется использование привозного каменного материала',
            'Можно работать с грунтами любой влажности из выемок',
            'Повышенные параметры качества конструкции, отсутствие сдвигов характерных для дискретных материалов',
            'Предотвращает появление просадок',
        ],
        minuses: ['Технология работ требует технологического перерыва и специализированной техники'],
    },
];
