import { h, Fragment } from 'preact';
import React from 'react';
import { useCitiesAndTpsContext } from '../CitiesAndTpsContext';
import { AnimatePresence, motion, useWillChange } from 'framer-motion';

import { TpsData } from '@/data/tps';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { tp } from '@/typograf';
import { WhiteArrowIcon } from '../../svg/WhiteArrowIcon';
import { DoubleArrowRightSvg } from '../../svg/DoubleArrowRightSvg';
import { CloseIcon } from '../../svg/CloseIcon';
import CountUp from 'react-countup';
import { projectsData, ProjectInfo } from '@/data/projects';
import classNames from 'classnames';
import { countDecimals } from '@/utils/strings';
import ClipReveal from '@/components/ClipReveal/ClipReveal';
import { getCSSCustomProp } from '@/utils/css';
import debounce from 'lodash.debounce';
import { PlayColoredSvg } from '../../svg/PlayColoredSvg';

const EXIT_DURATION = 0.5;

const transition = {
    duration: 0.67,
    ease: [0.33, 0, 0.67, 1],
};

const TpsView = () => {
    const offset = getCSSCustomProp(document.documentElement, '--app-border-radius', 'number') as number;
    const { setView, activeTpsSlug, setActiveTpsSlug } = useCitiesAndTpsContext();
    const willChange = useWillChange();
    const isMobile = window.matchMedia('(max-width: 1279px)').matches;
    const [width, setWidth] = useState(window.innerWidth);

    const data = TpsData.find((item) => item.slug === activeTpsSlug);
    const currentProjects = projectsData.filter((project) => project.slug === activeTpsSlug);
    const areasOfApplicationRef = useRef<HTMLDivElement>(null);
    const projectsRef = useRef<HTMLDivElement>(null);
    const currentProjectRef = useRef<HTMLDivElement>(null);

    const [isShowAreas, setIsShowAreas] = useState(false);
    const [isShowProjects, setIsShowProjects] = useState(false);
    const [currentProjectSlug, setCurrentProjectSlug] = useState('');

    const [projectListHiding, setProjectListHiding] = useState(false);

    const [areasOfApplicationWidth, setAreasOfApplicationWidth] = useState(0);
    const [projectsWidth, setProjectsWidth] = useState(0);
    const [currentProjectWidth, setCurrentProjectWidth] = useState(0);

    const [currentProjectData, setCurrentProjectData] = useState<ProjectInfo | null>(null);

    useEffect(() => {
        const debouncedOnResize = () => {
            debounce(() => {
                setWidth(window.innerWidth);
            }, 50);
        };

        window.addEventListener('resize', debouncedOnResize);

        return () => {
            window.removeEventListener('resize', debouncedOnResize);
        };
    }, []);

    useLayoutEffect(() => {
        if (areasOfApplicationRef.current) {
            setAreasOfApplicationWidth(areasOfApplicationRef.current.offsetWidth);
        }
        if (projectsRef.current) {
            setProjectsWidth(projectsRef.current.offsetWidth);
        }
        if (currentProjectRef.current) {
            setCurrentProjectWidth(currentProjectRef.current.offsetWidth);
        }
    }, [projectsWidth, currentProjectWidth, offset, width]);

    useEffect(() => {
        if (currentProjectSlug === '') {
            setCurrentProjectData(null);
        } else {
            const currentProject = projectsData.find((project) => project.projectSlug === currentProjectSlug);
            if (currentProject) {
                setCurrentProjectData(currentProject);
            }
        }
    }, [currentProjectSlug, currentProjectData]);

    return (
        <Fragment>
            {data && (
                <motion.div
                    variants={{
                        areas: {
                            x: isMobile ? -areasOfApplicationWidth - 8 : -areasOfApplicationWidth,
                        },
                        projects: {
                            x: isMobile ? -projectsWidth - 8 : -projectsWidth,
                        },
                        currentProject: {
                            x: -currentProjectWidth - 8,
                        },
                        initial: {
                            x: 0,
                            transition: {
                                delay: 0.67,
                                duration: 0.67,
                                ease: [0.33, 0, 0.67, 1],
                            },
                        },
                    }}
                    transition={transition}
                    animate={
                        isShowAreas
                            ? 'areas'
                            : isShowProjects
                            ? 'projects'
                            : currentProjectData
                            ? 'currentProject'
                            : 'initial'
                    }
                    className="tps-container"
                    style={{ willChange }}
                >
                    <div className="tps-content-wrapper">
                        <div className="tps-content">
                            <motion.button
                                className="tps-close-button button button-close"
                                aria-label="Закрыть"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        pointerEvents: 'auto',
                                    },
                                    hidden: {
                                        opacity: 0,
                                        pointerEvents: 'none',
                                        transition: {
                                            delay: 0.5,
                                        },
                                    },
                                    initial: {
                                        opacity: 0,
                                        pointerEvents: 'none',
                                    },
                                }}
                                transition={transition}
                                animate={isShowAreas || isShowProjects || currentProjectData ? 'hidden' : 'visible'}
                                onClick={() => {
                                    setActiveTpsSlug('');
                                    setView('choose-tps');
                                }}
                            >
                                <div className="button__inner">
                                    <CloseIcon />
                                </div>
                                <div className="button__inner button__inner-copy">
                                    <CloseIcon />
                                </div>
                            </motion.button>
                            <div className="tps-image">
                                <motion.picture
                                    initial={{
                                        y: '-105%',
                                    }}
                                    animate={{
                                        y: 0,
                                        transition: {
                                            delay: 0.67,
                                            duration: 0.67,
                                        },
                                    }}
                                    exit={{
                                        y: '105%',
                                        transition: {
                                            duration: EXIT_DURATION,
                                        },
                                    }}
                                    style={{ willChange }}
                                >
                                    <source
                                        media="(max-width: 1279px)"
                                        srcSet={`/static/img/tps/${data.image}@resize-1000x.webp`}
                                        type="image/webp"
                                    />
                                    <source
                                        media="(max-width: 1920px)"
                                        srcSet={`/static/img/tps/${data.image}@resize-1900x.webp`}
                                        type="image/webp"
                                    />
                                    <source
                                        media="(min-width: 1921px)"
                                        srcSet={`/static/img/tps/${data.image}@resize-2500x.webp`}
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet={`/static/img/tps/${data.image}@resize-2500x.webp`}
                                        type="image/webp"
                                    />
                                    <source
                                        media="(max-width: 1279px)"
                                        srcSet={`/static/img/tps/${data.image}@resize-1000x.jpg`}
                                    />
                                    <source
                                        media="(max-width: 1920px)"
                                        srcSet={`/static/img/tps/${data.image}@resize-1900x.jpg`}
                                    />
                                    <source
                                        media="(min-width: 1921px)"
                                        srcSet={`/static/img/tps/${data.image}@resize-2500x.jpg`}
                                    />
                                    <img
                                        alt={`Фотография - ${data.image}`}
                                        src={`/static/img/tps/${data.image}@resize-2500x.jpg`}
                                        width="2416"
                                        height="1812"
                                    />
                                </motion.picture>
                            </div>
                            <div
                                className={classNames('tps-body', {
                                    'tps-body-with-button-bottom': currentProjects.length > 0,
                                })}
                            >
                                <div className="tps-left">
                                    <div className="tps-station-info">
                                        <div className="tps-title h3">
                                            <ClipReveal delay={0.67}>{data.station}</ClipReveal>
                                        </div>
                                        <div className="tps-address text-lg">
                                            <ClipReveal delay={0.67}>{data.address}</ClipReveal>
                                        </div>
                                    </div>

                                    <div className="tps-values">
                                        <ClipReveal delay={0.67}>
                                            <div className="tps-value tps-thermal">
                                                <div className="tps-value__title text-sm">Тепловая мощность</div>
                                                <div className="tps-value__description h-text-lg">
                                                    <CountUp
                                                        start={0}
                                                        end={data.thermalPower}
                                                        decimals={countDecimals(data.thermalPower)}
                                                        decimal=","
                                                        separator=" "
                                                    />
                                                    <span> Гкал/ч</span>
                                                </div>
                                            </div>
                                        </ClipReveal>
                                        <ClipReveal delay={0.67}>
                                            <div className="tps-value tps-thermal">
                                                <div className="tps-value__title text-sm">Электрическая мощность</div>
                                                <div className="tps-value__description h-text-lg">
                                                    <CountUp
                                                        start={0}
                                                        end={data.electricPower}
                                                        decimals={countDecimals(data.electricPower)}
                                                        decimal=","
                                                        separator=" "
                                                    />
                                                    <span> МВт</span>
                                                </div>
                                            </div>
                                        </ClipReveal>
                                        <ClipReveal delay={0.67}>
                                            <div className="tps-value tps-thermal">
                                                <div className="tps-value__title text-sm">Вид топлива</div>
                                                <div className="tps-value__description h-text-lg">
                                                    {data.typeOfFuel}
                                                </div>
                                            </div>
                                        </ClipReveal>
                                    </div>
                                    {currentProjects.length > 0 && (
                                        <ClipReveal delay={0.67} className="experience-button">
                                            <button
                                                className="button button-secondary button-wide "
                                                onClick={() => {
                                                    setIsShowProjects(true);
                                                    setIsShowAreas(false);
                                                }}
                                            >
                                                <div className="button__inner">
                                                    <div className="button__text text-md">Смотреть опыт применения</div>
                                                    <div className="button__icon">
                                                        <DoubleArrowRightSvg />
                                                    </div>
                                                </div>
                                                <div className="button__inner button__inner-copy">
                                                    <div className="button__text text-md">Смотреть опыт применения</div>
                                                    <div className="button__icon">
                                                        <DoubleArrowRightSvg />
                                                    </div>
                                                </div>
                                            </button>
                                        </ClipReveal>
                                    )}
                                </div>
                                <div className="tps-right">
                                    <div className="tps-data">
                                        <div className="tps-data__value-title h-text-lg">
                                            <ClipReveal delay={0.67}>
                                                {tp('Объем образования вторичного сырья')}
                                            </ClipReveal>
                                        </div>
                                        <div className="tps-data__zola-unosa tps-data-recycle-item">
                                            <ClipReveal delay={0.67}>
                                                <div className="tps-data__zola-unosa-title text-sm">Зола-уноса</div>
                                            </ClipReveal>
                                            <ClipReveal delay={0.67}>
                                                <div className="tps-data__zola-unosa-value tps-data-recycle-item__value">
                                                    <span className="h3">
                                                        <CountUp
                                                            start={0}
                                                            end={data.zolaUnosa}
                                                            decimals={countDecimals(data.zolaUnosa)}
                                                            decimal=","
                                                            separator=" "
                                                        />
                                                    </span>
                                                    <span className="tps-data-recycle__units h-text-md">тонн</span>
                                                </div>
                                            </ClipReveal>
                                        </div>
                                        <div className="tps-data__fuel-slag tps-data-recycle-item">
                                            <ClipReveal delay={0.67}>
                                                <div className="tps-data__fuel-slag-title text-sm">топливный шлак</div>
                                            </ClipReveal>
                                            <ClipReveal delay={0.67}>
                                                <div className="tps-data__fuel-slag-value tps-data-recycle-item__value">
                                                    <span className="h3">
                                                        <CountUp
                                                            start={0}
                                                            end={data.fuelSlag}
                                                            decimals={countDecimals(data.fuelSlag)}
                                                            decimal=","
                                                            separator=" "
                                                        />
                                                    </span>
                                                    <span className="tps-data-recycle__units h-text-md">тонн</span>
                                                </div>
                                            </ClipReveal>
                                        </div>
                                        <div className="tps-data-available">
                                            <div className="tps-data__available-title h-text-lg">
                                                <ClipReveal delay={0.67}>{tp('Доступно к реализации')}</ClipReveal>
                                            </div>
                                            <div className="tps-data__available-list">
                                                <div className="tps-data__available-list__inner">
                                                    {data.rawMaterials.map((item, i) => {
                                                        return (
                                                            <ClipReveal delay={0.67} key={i}>
                                                                <div className="tps-data-badge text-sm">
                                                                    <div className="tps-data-badge__text">
                                                                        {tp(item.text)}
                                                                    </div>
                                                                    {item.description && (
                                                                        <div className="tps-data-badge__description">
                                                                            {tp(item.description)}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </ClipReveal>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tps-data__shipment">
                                            <ClipReveal delay={0.67}>
                                                <div className="tps-data__shipment-title h-text-lg">
                                                    {tp('Способы отгрузки продукции')}
                                                </div>
                                            </ClipReveal>
                                            <div className="tps-data__shipment-list">
                                                {data.shipment &&
                                                    data.shipment.length > 0 &&
                                                    data.shipment.map((item, i) => {
                                                        return (
                                                            <ClipReveal delay={0.67} key={i}>
                                                                <div className="tps-data-badge text-sm">
                                                                    <div className="tps-data-badge__text">{item}</div>
                                                                </div>
                                                            </ClipReveal>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div className="tps-data__areas">
                                            <ClipReveal delay={0.67}>
                                                <button
                                                    className="button-with-round-icon button-with-round-icon--primary"
                                                    onClick={() => {
                                                        setIsShowAreas(true);
                                                        setIsShowProjects(false);
                                                    }}
                                                >
                                                    <div className="button__icon">
                                                        <WhiteArrowIcon />
                                                    </div>
                                                    <div className="button__text text-sm">
                                                        смотреть
                                                        <br />
                                                        Сферы применения
                                                    </div>
                                                    <div className="button__text button__text-copy text-sm">
                                                        смотреть
                                                        <br />
                                                        Сферы применения
                                                    </div>
                                                </button>
                                            </ClipReveal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <motion.div
                        className="tps-areas-of-application"
                        ref={areasOfApplicationRef}
                        initial={false}
                        variants={{
                            visible: {
                                opacity: 1,
                                pointerEvents: 'auto',
                            },
                            hidden: {
                                opacity: 0,
                                pointerEvents: 'none',
                                transition: {
                                    duration: 0.7,
                                    delay: 0.35,
                                },
                            },
                            initial: {
                                opacity: 0,
                                pointerEvents: 'none',
                            },
                        }}
                        transition={{
                            ease: [0.33, 0, 0.67, 1],
                        }}
                        animate={isShowAreas ? 'visible' : 'hidden'}
                    >
                        <button
                            className="tps-close-button button button-close"
                            aria-label="Вернуться к описанию ТЭЦ"
                            onClick={() => {
                                setIsShowAreas(false);
                            }}
                        >
                            <div className="button__inner">
                                <CloseIcon />
                            </div>
                            <div className="button__inner button__inner-copy">
                                <CloseIcon />
                            </div>
                        </button>
                        <div className="tps-areas">
                            <div className="tps-areas__title h3">
                                <AnimatePresence mode="wait">
                                    {isShowAreas ? (
                                        <ClipReveal delay={0.35} slug={isShowAreas} key={isShowAreas}>
                                            Сферы применения золошлаков
                                        </ClipReveal>
                                    ) : (
                                        <div style={{ opacity: 0 }}>Сферы применения золошлаков</div>
                                    )}
                                </AnimatePresence>
                            </div>
                            <AnimatePresence mode="wait">
                                {isShowAreas ? (
                                    <ul
                                        className={classNames('tps-areas__list list-unstyled', {
                                            'tps-areas__list-grid': data.slug === 'novo-kemerovskaya-tets',
                                        })}
                                        key={isShowAreas}
                                    >
                                        {data.areasOfApplication.map((item, i) => {
                                            return (
                                                <ClipReveal
                                                    delay={0.35 + 0.15 * i}
                                                    slug={isShowAreas}
                                                    key={i}
                                                    className="tps-area"
                                                >
                                                    <li className="tps-area__li">
                                                        <div className="tps-area__title h4">{item.title}</div>
                                                        <ul className="tps-area-of-application__list gradient-list list-unstyled">
                                                            {item.list.map((itemInner, j) => {
                                                                return (
                                                                    <li
                                                                        className="tps-area-of-application__item text-lg"
                                                                        key={j}
                                                                    >
                                                                        {tp(itemInner)}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </li>
                                                </ClipReveal>
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <ul
                                        className={classNames('tps-areas__list list-unstyled', {
                                            'tps-areas__list-grid': data.slug === 'novo-kemerovskaya-tets',
                                        })}
                                        style={{ opacity: 0 }}
                                    >
                                        {data.areasOfApplication.map((item, i) => {
                                            return (
                                                <li className="tps-area" key={i}>
                                                    <div className="tps-area__title h4">{item.title}</div>
                                                    <ul className="tps-area-of-application__list gradient-list list-unstyled">
                                                        {item.list.map((itemInner, j) => {
                                                            return (
                                                                <li
                                                                    className="tps-area-of-application__item text-lg"
                                                                    key={j}
                                                                >
                                                                    {tp(itemInner)}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </AnimatePresence>
                        </div>
                    </motion.div>

                    <motion.div
                        className="tps-areas-of-application"
                        ref={projectsRef}
                        initial={false}
                        variants={{
                            visible: {
                                opacity: 1,
                                pointerEvents: 'auto',
                            },
                            hidden: {
                                opacity: 0,
                                pointerEvents: 'none',
                                transition: {
                                    delay: 0.7,
                                },
                            },
                            initial: {
                                opacity: 0,
                                pointerEvents: 'none',
                            },
                        }}
                        transition={{
                            ease: [0.33, 0, 0.67, 1],
                        }}
                        animate={isShowProjects ? 'visible' : 'hidden'}
                    >
                        <motion.button
                            variants={{
                                visible: {
                                    opacity: 1,
                                },
                                hidden: {
                                    opacity: 0,
                                },
                            }}
                            animate={projectListHiding ? 'hidden' : 'visible'}
                            transition={{
                                duration: 0.3,
                            }}
                            className="tps-close-button button button-close"
                            aria-label="Вернуться к описанию ТЭЦ"
                            onClick={() => {
                                setIsShowProjects(false);
                            }}
                        >
                            <div className="button__inner">
                                <CloseIcon />
                            </div>
                            <div className="button__inner button__inner-copy">
                                <CloseIcon />
                            </div>
                        </motion.button>
                        <AnimatePresence mode="popLayout">
                            <div className="tps-projects-list" key={isShowProjects}>
                                {isShowProjects &&
                                    currentProjects.map((item, i) => {
                                        return (
                                            <button
                                                className="tps-project-item"
                                                onClick={() => {
                                                    setProjectListHiding(true);
                                                    setIsShowProjects(false);
                                                    setCurrentProjectSlug(item.projectSlug);
                                                }}
                                                key={i}
                                            >
                                                <ClipReveal delay={0.4}>
                                                    <div className="tps-project-item__image">
                                                        {item.image && (
                                                            <picture>
                                                                <source
                                                                    media="(max-width: 767px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-360x.webp`}
                                                                    type="image/webp"
                                                                />
                                                                <source
                                                                    media="(max-width: 1023px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-400x.webp`}
                                                                    type="image/webp"
                                                                />
                                                                <source
                                                                    media="(max-width: 1279px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-460x.webp`}
                                                                    type="image/webp"
                                                                />
                                                                <source
                                                                    media="(max-width: 1920px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-600x.webp`}
                                                                    type="image/webp"
                                                                />
                                                                <source
                                                                    media="(min-width: 1921px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-708x.webp`}
                                                                    type="image/webp"
                                                                />
                                                                <source
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-2600x.webp`}
                                                                    type="image/webp"
                                                                />
                                                                <source
                                                                    media="(max-width: 767px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-360x.jpg`}
                                                                />
                                                                <source
                                                                    media="(max-width: 1023px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-400x.jpg`}
                                                                />
                                                                <source
                                                                    media="(max-width: 1279px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-460x.jpg`}
                                                                />
                                                                <source
                                                                    media="(max-width: 1920px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-600x.jpg`}
                                                                />
                                                                <source
                                                                    media="(min-width: 1921px)"
                                                                    srcSet={`/static/img/projects/${item.image}-sm@resize-708x.jpg`}
                                                                />
                                                                <img
                                                                    alt={item.title}
                                                                    src={`/static/img/projects/${item.image}-sm@resize-2600x.jpg`}
                                                                    width="2416"
                                                                    height="1812"
                                                                />
                                                            </picture>
                                                        )}
                                                    </div>
                                                </ClipReveal>
                                                <div className="tps-project-item__title text-lg">
                                                    <ClipReveal delay={0.55}>{tp(item.title)}</ClipReveal>
                                                </div>
                                            </button>
                                        );
                                    })}
                            </div>
                        </AnimatePresence>
                    </motion.div>

                    <motion.div
                        className="tps-project tps-project-absolute"
                        ref={currentProjectRef}
                        initial={false}
                        variants={{
                            visible: {
                                opacity: 1,
                                pointerEvents: 'auto',
                            },
                            hidden: {
                                opacity: 0,
                                pointerEvents: 'none',
                                transition: {
                                    delay: 0.7,
                                },
                            },
                            initial: {
                                opacity: 0,
                                pointerEvents: 'none',
                            },
                        }}
                        transition={{
                            ease: [0.33, 0, 0.67, 1],
                            duration: 0.3,
                        }}
                        animate={currentProjectData ? 'visible' : 'hidden'}
                    >
                        <motion.button
                            variants={{
                                visible: {
                                    opacity: 1,
                                    pointerEvents: 'auto',
                                    transition: {
                                        delay: 0.5,
                                    },
                                },
                                hidden: {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    transition: {
                                        delay: 0.5,
                                    },
                                },
                                initial: {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                },
                            }}
                            transition={transition}
                            animate={currentProjectData ? 'visible' : 'hidden'}
                            className="tps-close-button button button-close"
                            aria-label="Вернуться к описанию ТЭЦ"
                            onClick={() => {
                                setCurrentProjectSlug('');
                                setProjectListHiding(false);
                                setIsShowProjects(true);
                            }}
                        >
                            <div className="button__inner">
                                <CloseIcon />
                            </div>
                            <div className="button__inner button__inner-copy">
                                <CloseIcon />
                            </div>
                        </motion.button>
                        <AnimatePresence mode="sync">
                            {currentProjectData && (
                                <div className="tps-content" key={currentProjectData.slug}>
                                    <div className="tps-image">
                                        <motion.picture
                                            initial={{
                                                y: '-105%',
                                            }}
                                            animate={{
                                                y: 0,
                                                transition: {
                                                    delay: 0.67,
                                                    duration: 0.67,
                                                },
                                            }}
                                            exit={{
                                                y: '105%',
                                                transition: {
                                                    duration: EXIT_DURATION,
                                                },
                                            }}
                                            style={{ willChange }}
                                        >
                                            <source
                                                media="(max-width: 767px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-400x.webp`}
                                                type="image/webp"
                                            />
                                            <source
                                                media="(max-width: 1023px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-708x.webp`}
                                                type="image/webp"
                                            />
                                            <source
                                                media="(max-width: 1279px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-1000x.webp`}
                                                type="image/webp"
                                            />
                                            <source
                                                media="(max-width: 1920px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-1900x.webp`}
                                                type="image/webp"
                                            />
                                            <source
                                                media="(min-width: 1921px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-2500x.webp`}
                                                type="image/webp"
                                            />
                                            <source
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-2600x.webp`}
                                                type="image/webp"
                                            />
                                            <source
                                                media="(max-width: 767px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-400x.jpg`}
                                            />
                                            <source
                                                media="(max-width: 1023px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-708x.jpg`}
                                            />
                                            <source
                                                media="(max-width: 1279px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-1000x.jpg`}
                                            />
                                            <source
                                                media="(max-width: 1920px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-1900x.jpg`}
                                            />
                                            <source
                                                media="(min-width: 1921px)"
                                                srcSet={`/static/img/projects/${currentProjectData.image}@resize-2500x.jpg`}
                                            />
                                            <img
                                                alt={currentProjectData.title}
                                                src={`/static/img/projects/${currentProjectData.image}@resize-2600x.jpg`}
                                                width="2416"
                                                height="1812"
                                            />
                                        </motion.picture>

                                        <div className="project-badges-and-video">
                                            <div className="project-badges">
                                                <ClipReveal delay={0.67}>
                                                    <div className="badge text-sm">
                                                        <div className="badge__label">Материал:</div>
                                                        <div className="badge__value">
                                                            {currentProjectData.material}
                                                        </div>
                                                    </div>
                                                </ClipReveal>

                                                <ClipReveal delay={0.67}>
                                                    <div className="badge text-sm">
                                                        <div className="badge__label">Статус:</div>
                                                        <div className="badge__value">{currentProjectData.status}</div>
                                                    </div>
                                                </ClipReveal>
                                            </div>

                                            {currentProjectData.video && (
                                                <ClipReveal delay={0.3}>
                                                    <button
                                                        className="project-video-button"
                                                        data-lit-popup-open={
                                                            currentProjectData.video.popupName + '-map'
                                                        }
                                                    >
                                                        <div className="project-video-button__left text-sm">
                                                            <div className="project-video-button__info">
                                                                Видео · {currentProjectData.video.duration}
                                                            </div>
                                                            <div className="project-video-button__name">
                                                                {currentProjectData.video.name}
                                                            </div>
                                                        </div>
                                                        <div className="project-video-button__icon">
                                                            <PlayColoredSvg />
                                                        </div>
                                                    </button>
                                                </ClipReveal>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classNames('project-body', currentProjectData.template)}>
                                        <div className="project-left">
                                            <div className="project-title h4">
                                                <ClipReveal delay={0.67}>{tp(currentProjectData.title)}</ClipReveal>
                                            </div>
                                            {currentProjectData.description &&
                                                currentProjectData.template !== 'template-two' && (
                                                    <ClipReveal delay={0.67}>
                                                        <div
                                                            className="project-description text-lg"
                                                            dangerouslySetInnerHTML={{
                                                                __html: tp(currentProjectData.description),
                                                            }}
                                                        />
                                                    </ClipReveal>
                                                )}
                                            <div className="project-value">
                                                <div className="project-value__label text-sm">
                                                    <ClipReveal delay={0.67}>
                                                        {tp(currentProjectData.valueOfMaterialsLabel)}
                                                    </ClipReveal>
                                                </div>
                                                <ClipReveal delay={0.67}>
                                                    <div
                                                        className={classNames('project-value__value h3', {
                                                            'project-value-with-pre':
                                                                currentProjectData.valueOfMaterialsPreLabel,
                                                        })}
                                                    >
                                                        {currentProjectData.valueOfMaterialsPreLabel && (
                                                            <div className="project-value__prelabel">
                                                                {currentProjectData.valueOfMaterialsPreLabel}
                                                            </div>
                                                        )}
                                                        <div className="project-value__num">
                                                            <CountUp
                                                                start={0}
                                                                end={currentProjectData.valueOfMaterials}
                                                                decimals={countDecimals(
                                                                    currentProjectData.valueOfMaterials,
                                                                )}
                                                                decimal=","
                                                                separator=" "
                                                            />
                                                        </div>
                                                        <div className="project-value__units">
                                                            {currentProjectData.valueOfMaterialsUnits}
                                                        </div>
                                                    </div>
                                                </ClipReveal>
                                            </div>
                                        </div>

                                        <div className="project-right">
                                            {currentProjectData.subtitle && (
                                                <div className="project-subtitle h4">
                                                    <ClipReveal delay={0.67}>
                                                        {tp(currentProjectData.subtitle)}
                                                    </ClipReveal>
                                                </div>
                                            )}
                                            {currentProjectData.description &&
                                                currentProjectData.template === 'template-two' && (
                                                    <ClipReveal delay={0.67}>
                                                        <div
                                                            className="project-description text-lg"
                                                            dangerouslySetInnerHTML={{
                                                                __html: tp(currentProjectData.description),
                                                            }}
                                                        />
                                                    </ClipReveal>
                                                )}
                                            {currentProjectData.economic && currentProjectData.economic.length > 0 && (
                                                <ul className="project-economic list-unstyled">
                                                    {currentProjectData.economic.map((item, i) => {
                                                        return (
                                                            <ClipReveal tagName="li" delay={0.67} key={i}>
                                                                <div className="project-economic-item">
                                                                    <div className="project-economic-item-value h-text-lg">
                                                                        {item.valuePreLabel && (
                                                                            <div className="project-economic-item-value__pre">
                                                                                {item.valuePreLabel}
                                                                            </div>
                                                                        )}
                                                                        <div className="project-economic-item-value__num">
                                                                            <CountUp
                                                                                start={0}
                                                                                decimals={countDecimals(item.value)}
                                                                                decimal=","
                                                                                end={item.value}
                                                                                separator=" "
                                                                            />
                                                                        </div>
                                                                        <div className="project-economic-item-value__units">
                                                                            {item.units}
                                                                        </div>
                                                                    </div>
                                                                    <div className="project-economic-item-label text-sm">
                                                                        {tp(item.valueLabel)}
                                                                    </div>
                                                                </div>
                                                            </ClipReveal>
                                                        );
                                                    })}
                                                </ul>
                                            )}
                                            {currentProjectData.stageList &&
                                                currentProjectData.stageList.length > 0 && (
                                                    <ul className="project-stage-list gradient-list list-unstyled">
                                                        {currentProjectData.stageList.map((item, i) => {
                                                            return (
                                                                <li key={i} className="text-lg">
                                                                    <ClipReveal delay={0.67}>{tp(item)}</ClipReveal>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            {currentProjectData.footerTitle && (
                                                <div
                                                    className={classNames('project-footer-title text-xl', {
                                                        'project-footer-title-top':
                                                            currentProjectData.image === 'item-7',
                                                    })}
                                                >
                                                    <ClipReveal delay={0.67}>
                                                        {tp(currentProjectData.footerTitle)}
                                                    </ClipReveal>
                                                </div>
                                            )}
                                            {currentProjectData.caption && (
                                                <div className="project-caption text-sm">
                                                    <ClipReveal delay={0.67}>
                                                        {tp(currentProjectData.caption)}
                                                    </ClipReveal>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </AnimatePresence>
                    </motion.div>
                </motion.div>
            )}
        </Fragment>
    );
};

export default TpsView;
