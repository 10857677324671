import type { PossibilitiesProjectInfo } from '@/data/projects';
import { h } from 'preact';
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';

const PossibilitiesDetailContext = createContext<{
    activeProjectSlug: string;
    projects: PossibilitiesProjectInfo[];
    setActiveProjectSlug: Dispatch<SetStateAction<string>>;
    isAnimating: boolean;
    setIsAnimating: Dispatch<SetStateAction<boolean>>;
    setProjects: Dispatch<SetStateAction<PossibilitiesProjectInfo[]>>;
}>({
    activeProjectSlug: '',
    projects: [],
    setActiveProjectSlug: () => {},
    isAnimating: false,
    setIsAnimating: () => {},
    setProjects: () => {},
});

export const PossibilitiesDetailContextProvider = ({
    projects: initialProjects,
    children,
}: {
    projects: PossibilitiesProjectInfo[];
    children: ReactNode;
}) => {
    const [activeProjectSlug, setActiveProjectSlug] = useState(initialProjects[0].projectSlug);
    const [isAnimating, setIsAnimating] = useState(false);
    const [projects, setProjects] = useState(initialProjects);

    return (
        <PossibilitiesDetailContext.Provider
            value={{
                activeProjectSlug,
                projects,
                setActiveProjectSlug,
                isAnimating,
                setIsAnimating,
                setProjects,
            }}
        >
            {children}
        </PossibilitiesDetailContext.Provider>
    );
};

export const usePossibilitiesDetailContext = () => useContext(PossibilitiesDetailContext);
