import barba from '@barba/core';
import gsap from 'gsap';

import type { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

import idleDetect from '../inits/idle-detect';

import { IdleQueue } from 'idlize/IdleQueue.mjs';

export function initBaseView() {
    const queue = new IdleQueue();

    barba.hooks.beforeEnter((data) => {
        document.body.classList.remove('no-scroll');
        setTimeout(() => {
            gsap.to(window, {
                scrollTo: { y: 0 },
                duration: 0,
            });
        }, 50);

        if (data) {
            document.body.classList.add(data.next.namespace);
        }

        if (NODE_ENV !== 'development') {
            queue.pushTask(() => {
                idleDetect.init(data?.next.namespace || '');
            });
        }
    });

    barba.hooks.leave(() => {
        const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));
        if (popups.length) {
            popups.forEach((popup) => {
                if (popup.instance.isOpen) {
                    popup.close();
                }
                popup.classList.remove('lit-popup--opened');
                popup.classList.remove('is-show');
            });
        }
    });

    barba.hooks.beforeLeave(() => {
        queue.clearPendingTasks();
    });

    barba.hooks.afterLeave((data) => {
        if (data) {
            document.body.classList.remove(data.current.namespace);
        }
        idleDetect.destroy();
    });
}
