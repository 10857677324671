import { h } from 'preact';
import React from 'react';
import { motion, useWillChange } from 'framer-motion';
import CountUp from 'react-countup';
import type { PossibilitiesProjectInfo } from '@/data/projects';
import classNames from 'classnames';
import { CloseIcon } from '../svg/CloseIcon';
import { tp } from '@/typograf';
import { countDecimals } from '@/utils/strings';
import ClipReveal from '../ClipReveal/ClipReveal';
import { PlayColoredSvg } from '../svg/PlayColoredSvg';
import { PlusSvg } from '../svg/PlusSvg';
import { MinusSvg } from '../svg/MinusSvg';

const PossibilitiesDetailLayout = ({
    slug,
    material,
    status,
    title,
    description,
    image,
    template,
    pluses,
    minuses,
    info,
}: PossibilitiesProjectInfo) => {
    const willChange = useWillChange();
    return (
        <motion.div className="tps-project">
            <button
                className="tps-close-button button button-close"
                aria-label="Закрыть"
                data-lit-popup-close="possibilities-detail-popup"
            >
                <div className="button__inner">
                    <CloseIcon />
                </div>
                <div className="button__inner button__inner-copy">
                    <CloseIcon />
                </div>
            </button>

            <div className="tps-content">
                <div className="tps-image">
                    <motion.picture
                        initial={{ y: '-105%' }}
                        animate={{
                            y: 0,
                            transition: {
                                delay: 0.1,
                                duration: 0.67,
                                ease: [0.33, 0, 0.67, 1],
                            },
                        }}
                        exit={{ y: '105%' }}
                        key={slug}
                        style={{ willChange }}
                    >
                        <source
                            media="(max-width: 1279px)"
                            srcSet={`/static/img/possibilities-detail/${image}@resize-1000x.webp`}
                            type="image/webp"
                        />
                        <source
                            media="(max-width: 1920px)"
                            srcSet={`/static/img/possibilities-detail/${image}@resize-1900x.webp`}
                            type="image/webp"
                        />
                        <source
                            media="(min-width: 1921px)"
                            srcSet={`/static/img/possibilities-detail/${image}@resize-2500x.webp`}
                            type="image/webp"
                        />
                        <source
                            srcSet={`/static/img/possibilities-detail/${image}@resize-2500x.webp`}
                            type="image/webp"
                        />
                        <source
                            media="(max-width: 1279px)"
                            srcSet={`/static/img/possibilities-detail/${image}@resize-1000x.jpg`}
                        />
                        <source
                            media="(max-width: 1920px)"
                            srcSet={`/static/img/possibilities-detail/${image}@resize-1900x.jpg`}
                        />
                        <source
                            media="(min-width: 1921px)"
                            srcSet={`/static/img/possibilities-detail/${image}@resize-2500x.jpg`}
                        />
                        <img
                            alt={`Фотография - ${title}`}
                            src={`/static/img/possibilities-detail/${image}@resize-2500x.jpg`}
                            width="2416"
                            height="1812"
                        />
                    </motion.picture>
                </div>
                <div className={classNames('project-body', template)}>
                    <div className="project-left">
                        <div className="project-title h4">
                            <ClipReveal slug={slug} delay={0.3}>
                                {tp(title)}
                            </ClipReveal>
                        </div>
                        <ClipReveal slug={slug}>
                            <div
                                className={classNames('project-description-number-list', {
                                    'small-list': info.length < 6,
                                })}
                            >
                                {info.map((item, i) => (
                                    <div className="project-description-number-list-item" key={i}>
                                        <div className="number-list-item-left text-s">{i + 1}</div>
                                        <div className="number-list-item-right">
                                            <div className="number-list-item-right-title h-text-md">{item.title}</div>
                                            <div
                                                className="number-list-item-right-description text-s"
                                                dangerouslySetInnerHTML={{ __html: tp(item.subtitle) }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </ClipReveal>
                    </div>

                    <div className="project-right">
                        <ClipReveal slug={slug} delay={0.4}>
                            <div className="possibilities-right-info">
                                <div className="pluses">
                                    <div className="possibilities-right-info-title h-text-lg">Плюсы:</div>
                                    <div className="possibilities-right-info-items">
                                        {pluses.map((item, i) => (
                                            <div className="possibilities-right-info-item" key={i}>
                                                <div className="possibilities-info-item-left">
                                                    <PlusSvg />
                                                </div>
                                                <div className="possibilities-info-item-right text-s">{item}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="minuses">
                                    <div className="possibilities-right-info-title h-text-lg">Минусы:</div>
                                    <div className="possibilities-right-info-items">
                                        {minuses.map((item, i) => (
                                            <div className="possibilities-right-info-item" key={i}>
                                                <div className="possibilities-info-item-left">
                                                    <MinusSvg />
                                                </div>
                                                <div className="possibilities-info-item-right text-s">{item}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </ClipReveal>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default PossibilitiesDetailLayout;
