import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';
import barba from '@barba/core';

let timer: NodeJS.Timeout | null;
let interval: NodeJS.Timer | null;

const resetTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }
    setTimer();
};

const setTimer = () => {
    timer = setTimeout(() => {
        if (idlePopup) {
            idlePopup.open();
        }
    }, 1000 * TIME_TO_IDLE);
};

const TIME_TO_IDLE = 120; // in seconds
const TIME_TO_IDLE_COMPLETE = 60; // in seconds
const idlePopup = document.querySelector<LitPopupElement>('app-lit-popup[data-lit-popup="idle-popup"]');
const idlePopupTimer = idlePopup?.querySelector('.js-idle-popup-timer');
let TIME_VALUE = TIME_TO_IDLE_COMPLETE;

const onPopupOpen = () => {
    interval = setInterval(() => {
        if (TIME_VALUE > 1) {
            TIME_VALUE -= 1;
        } else {
            cancleAllTimersAndListeners();
            idlePopup?.close();
            barba.go(ROOT_PATH);
            TIME_VALUE = 0;
        }
        if (idlePopupTimer) {
            idlePopupTimer.innerHTML = `${TIME_VALUE}`;
        }
    }, 1000);
};

const onPopupClose = () => {
    resetTimer();
    if (interval) {
        clearInterval(interval);
    }

    TIME_VALUE = TIME_TO_IDLE_COMPLETE;

    if (idlePopup) {
        idlePopup.addEventListener(
            'close-complete',
            () => {
                if (idlePopupTimer) {
                    idlePopupTimer.innerHTML = `${TIME_VALUE}`;
                }
            },
            { once: true },
        );
    }
};

function init(namespace: string) {
    if (namespace === 'landing-page' || namespace === 'index-page') {
        return;
    }

    TIME_VALUE = TIME_TO_IDLE_COMPLETE;
    setTimer();
    if (idlePopupTimer) {
        idlePopupTimer.innerHTML = `${TIME_VALUE}`;
    }
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('click', resetTimer);
    document.addEventListener('touchstart', resetTimer);
    document.addEventListener('keypress', resetTimer);

    if (idlePopup) {
        idlePopup.addEventListener('open', onPopupOpen);
        idlePopup.addEventListener('close', onPopupClose);
    }
}

const cancleAllTimersAndListeners = () => {
    if (timer) {
        clearTimeout(timer);
    }
    document.removeEventListener('mousemove', resetTimer);
    document.removeEventListener('click', resetTimer);
    document.removeEventListener('touchstart', resetTimer);
    document.removeEventListener('keypress', resetTimer);

    if (idlePopup) {
        idlePopup.removeEventListener('open', onPopupOpen);
        idlePopup.removeEventListener('close', onPopupClose);
    }
    if (interval) {
        clearInterval(interval);
    }
    interval = null;
};

function destroy() {
    cancleAllTimersAndListeners();
}

const _module = { init, destroy };

export default _module;
